import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Design`}</h4>
    <p>{`Logic chip design involves specification, logic design, physical design, and validation and verification. Specification determines how the chip should operate in the system using it.  Logic design creates a schematic model of interconnected electrical components. Physical design translates this model to a physical layout of electrical components and interconnects, the wires that connect components. Validation and verification ensure chips based on the design will operate as expected.`}</p>
    <p>{`The United States designs most of the world's logic chips, with South Korea, Europe, Japan, Taiwan, and China each capturing small shares. High-value categories in logic chip design include high-end CPU design, discrete GPU design, FPGA design, and AI ASIC design.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      